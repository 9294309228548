import { useEffect, useReducer, useState } from 'react'
import useTicker from './useTicker'

export const STATE_START = 'start'
export const STATE_REST = 'rest'
export const STATE_WORK = 'work'

const ACTION_PAUSE = 'pause'
const ACTION_GOTO = 'goto'
const ACTION_TICK = 'tick'

const actions = {
  pause: value => ({ type: 'pause', payload: Boolean(value) }),
  goto: index => ({ type: 'goto', payload: index })
}

const roundReducer = round => {
  return round + 1
}

const repeatReducer = state => {
  let { index, excercise, excercises, repeat, round } = state

  // change excercise
  if (repeat >= excercise.repeat) {
    if (index > excercises.length) {
      state.round = roundReducer(state.round)
    }

    index = (index + 1) % excercises.length
    excercise = excercises[index]
    repeat = 0
  }

  return {
    ...state,
    elapsed: 0,
    state: STATE_WORK,
    excercise,
    repeat,
    round,
    index
  }
}

const restReducer = state => {
  const { excercise, repeat } = state

  const _state = {
    ...state,
    repeat: repeat + 1
  }

  return excercise.restTime
    ? {
        ..._state,
        elapsed: 0,
        state: STATE_REST
      }
    : repeatReducer(_state) // next excerice
}

const tickReducer = state => {
  const { elapsed, isPaused } = state

  return {
    ...state,
    elapsed: isPaused ? elapsed : elapsed + 1
  }
}

const clamp = (min, max, value) => Math.max(min, Math.min(value, max))

const reducer = (state, action) => {
  const { elapsed, excercise, excercises } = state

  switch (action.type) {
    case ACTION_PAUSE:
      return {
        ...state,
        isPaused: action.payload
      }

    case ACTION_GOTO:
      const _index = clamp(0, excercises.length - 1, action.payload)

      return {
        ...state,
        state: STATE_WORK,
        elapsed: 0,
        repeat: 0,
        index: _index,
        excercise: excercises[_index]
      }

    case ACTION_TICK:
      switch (state.state) {
        case STATE_START:
          // start
          return {
            ...state,
            state: STATE_WORK
          }

        case STATE_REST:
          if (elapsed < excercise.restTime) {
            return tickReducer(state)
          }

          return repeatReducer(state)

        case STATE_WORK:
          if (elapsed < excercise.workTime) {
            return tickReducer(state)
          }

          return restReducer(state)

        default:
          return state
      }

    default:
      return state
  }
}

const useTimer = (excercises, events = {}) => {
  const {
    onChange = null,
    onStartExcercise = null,
    onStartRest = null
  } = events

  const [
    { elapsed, excercise, index, state, repeat, round, isPaused },
    dispatch
  ] = useReducer(reducer, {
    elapsed: 0,
    index: 0,
    excercises,
    excercise: excercises[0],
    maxTime: excercises[0].workTime,
    state: STATE_WORK,
    repeat: 0,
    round: 0,
    isPaused: false
  })
  const [prevState, setPrevState] = useState(null)
  useTicker(1000, function () {
    dispatch({ type: 'tick' })
  })

  useEffect(() => {
    if (state !== prevState) {
      onChange && onChange()

      switch (state) {
        case STATE_REST:
          onStartRest && onStartRest()
          break

        case STATE_WORK:
          onStartExcercise && onStartExcercise()
          break

        default:
      }
    }
    setPrevState(state)
  }, [prevState, setPrevState, onChange, onStartRest, onStartExcercise, state])

  return {
    elapsed,
    index,
    nextIndex: (index + 1) % excercises.length,
    excercise,
    state,
    isPaused,
    repeat,
    round,
    setPause: value => dispatch(actions.pause(value)),
    goTo: index => dispatch(actions.goto(index))
  }
}

export default useTimer
