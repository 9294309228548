import { useEffect } from 'react'

const useTicker = (sleep, onTick, isRunning = true) => {
  function startTick (sleep) {
    return setTimeout(() => {
      onTick && onTick()
      return isRunning ? startTick(sleep) : null
    }, sleep)
  }

  useEffect(() => {
    const id = startTick(sleep)
    return () => {
      clearInterval(id)
    }
  }, [sleep]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useTicker
