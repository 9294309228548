import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import './App.css'
import useTimer, { STATE_WORK } from './hooks/useTimer'

const isNil = value => typeof value === 'undefined' || value === null

const useBeep = (opts = {}) => {
  const audioRef = useRef()
  const [volume, setVolume] = useState(
    isNil(opts.volume) ? 1.0 : Number(opts.volume)
  )
  const [bust, setBust] = useState(0)

  useEffect(() => {
    if (audioRef.current && bust !== 0) {
      audioRef.current.play()
    }
  }, [audioRef, bust])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume
    }
  }, [audioRef, volume])

  return {
    audioRef,
    volume,
    setVolume: v => {
      setVolume(Math.min(1, Math.max(0, v)))
    },
    beep: () => {
      setBust(Date.now())
    }
  }
}

const useExcercises = plan => {
  return useMemo(() => {
    const defaultWork = plan.workTime || 60
    const defaultRest = plan.restTime || 0
    const defaultRepeat = plan.repeat || 1

    // impute default work & rest times
    const _plan = plan.excercises.map(excercise => ({
      workTime: excercise.workTime || defaultWork,
      restTime: excercise.restTime || defaultRest,
      repeat: excercise.repeat || defaultRepeat,
      ...excercise
    }))

    // compute single round total time
    _plan.roundTotal = _plan.reduce(
      (total, { workTime, restTime }) => total + workTime + restTime,
      0
    )

    return _plan
  }, [plan])
}

const Beep = ({ beeper, sample = 'beep-06.mp3' }) => (
  <audio ref={beeper.audioRef}>
    <source src={sample} type='audio/mpeg' />
    Your browser does not support the audio element.
  </audio>
)

const formatTime = t => {
  const ss = t % 60
  const mm = (t - ss) / 60
  return `${mm > 0 ? `${mm}'` : ''}${ss > 0 ? `${ss}"` : ''}`
}

const formatExcercise = excercise =>
  `${excercise.repeat > 1 ? `${excercise.repeat} x ` : ''}${formatTime(
    excercise.workTime
  )}${excercise.restTime ? `/${formatTime(excercise.restTime)}` : ''} ${
    excercise.name
  }`

const asTxt = excercises => {
  return excercises.map(ex => `- ${formatExcercise(ex)}`).join('\n')
}

const ExcerciseImage = ({ excercise, pause = false }) => {
  const { image: { type, flip = false } = {} } = excercise
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      if (pause) {
        ref.current.pause()
      } else {
        ref.current.play()
      }
    }
  }, [pause])

  switch (type) {
    case 'video':
      return (
        <video
          id='player'
          autoPlay={!pause}
          loop
          muted
          playsInline
          src={excercise.image.url}
          className={flip ? 'flip-h' : ''}
          ref={ref}
        />
      )

    case 'image':
      return (
        <img
          className='ex-image'
          src={excercise.image.url}
          alt={'Harjoite: ' + excercise.name}
        />
      )

    default:
      return null
  }
}

const formatFileName = title =>
  title
    ? title
        .toLowerCase()
        .replace(/["']/g, ' ')
        .replace(/ +/g, ' ')
        .replace(/[ /\\]+/g, '-')
    : ''

const DownloadLink = ({ title, excercises, className = '' }) => {
  const blob = new Blob([asTxt(excercises)], { type: 'text/plain' })

  return (
    <a
      className={className}
      href={URL.createObjectURL(blob)}
      download={formatFileName(title)}
    >
      <i className='fas fa-download'></i> Lataa (txt)
    </a>
  )
}

const Header = ({ plan, excercises }) => {
  return (
    <header className='header'>
      <h1>{plan.title}</h1>"
      <DownloadLink
        title={plan.title}
        excercises={excercises}
        className='download'
      />
    </header>
  )
}
const ToggleBtn = ({ onClick }) => {
  return (
    <div className='sm-only' style={{ position: 'fixed', right: 4, top: 4 }}>
      <button className='btn' onClick={onClick}>
        <i class='fas fa-sync-alt'></i>
      </button>
    </div>
  )
}

const Player = ({ plan }) => {
  const excercises = useExcercises(plan)
  const startBeeper = useBeep()
  const restBeeper = useBeep()
  const [minimalView, setMinimalView] = useState('current')

  const handleStart = () => {
    startBeeper.beep()
  }

  const handleRest = () => {
    restBeeper.beep()
  }

  const handleToggle = () => {
    setMinimalView(minimalView === 'current' ? 'next' : 'current')
  }

  const {
    index: current,
    elapsed,
    nextIndex,
    excercise,
    state,
    isPaused,
    setPause,
    repeat,
    round,
    goTo
  } = useTimer(excercises, {
    onStartExcercise: handleStart,
    onStartRest: handleRest
  })

  const handleClick = index => {
    goTo(index)
  }

  const handlePauseClick = () => {
    setPause(!isPaused)
  }

  return (
    <>
      <div
        id='grid'
        className={['full-height', 'toggled-' + minimalView].join(' ')}
      >
        <Header plan={plan} excercises={excercises} />
        <div id='listing'>
          <div
            style={{
              width: '100%',
              boxSizing: 'border-box'
            }}
          >
            {excercises.map((excercise, i) => (
              <div
                key={i}
                style={{}}
                className={
                  current === i
                    ? 'item current'
                    : nextIndex === i
                    ? 'item next'
                    : 'item'
                }
              >
                <span style={{ flexGrow: 1 }}>
                  <button
                    className='link'
                    onClick={e => {
                      e.preventDefault()
                      handleClick(i)
                    }}
                  >
                    {formatExcercise(excercise)}
                  </button>
                </span>
              </div>
            ))}
          </div>
        </div>
        <ToggleBtn onClick={handleToggle} />
        <div id='current-video'>
          <div
            className='card current-video h-center'
            onClick={handlePauseClick}
          >
            <h2>Nyt</h2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {state === STATE_WORK ? (
                <ExcerciseImage excercise={excercise} pause={isPaused} />
              ) : (
                <div className='rest-placeholder center'>Palautus</div>
              )}
              {isPaused && (
                <i
                  style={{
                    position: 'absolute',
                    fontSize: '64px',
                    color: 'yellowgreen'
                  }}
                  className='fas fa-play'
                />
              )}
            </div>
          </div>
          <div className='card next-video h-center'>
            <h2>Seuraavaksi</h2>
            <ToggleBtn onClick={handleToggle} />
            <ExcerciseImage
              excercise={excercises[nextIndex]}
              pause={isPaused}
            />
          </div>
        </div>
        <footer>
          <button
            className='btn tight'
            style={{ marginRight: '8px' }}
            onClick={handlePauseClick}
          >
            {isPaused ? (
              <i class='fas fa-play'></i>
            ) : (
              <i class='fas fa-pause'></i>
            )}
          </button>
          <div style={{ width: '100%', display: 'flex' }}>
            <div
              className='timer-bar'
              style={{
                background: state === STATE_WORK ? 'green' : 'tomato',
                width: `${(100 * elapsed) /
                  (state === STATE_WORK
                    ? excercise.workTime
                    : excercise.restTime)}%`
              }}
            />
          </div>
          <div className='repeat-counter'>
            Kierros: {round + 1}
            <br />
            Sarja: {Math.min(repeat + 1, excercise.repeat)}/{excercise.repeat}
          </div>
        </footer>
      </div>
      <Beep beeper={startBeeper} sample='beep-06.mp3' />
      <Beep beeper={restBeeper} sample='horn-01.mp3' />
    </>
  )
}

const Splash = ({ plan, onClick }) => {
  const excercises = useExcercises(plan)

  const roundDuration = new Date(excercises.roundTotal * 1000)
  const mins = roundDuration.getMinutes()
  const secs = roundDuration.getSeconds()

  return (
    <>
      <div className='splash'>
        <h1 style={{ color: 'white' }}>{plan.title}</h1>
        <h2 style={{ color: 'white' }}>
          Kierroksen kesto: {mins ? `${mins}'` : ''}
          {secs ? `${secs}"` : ''}
        </h2>
        <button className='btn' onClick={onClick}>
          Aloita
        </button>
        <br />
        <DownloadLink title={plan.title} excercises={excercises} />
      </div>
    </>
  )
}

const withBrowser = fn => {
  if (typeof window !== 'undefined') {
    return fn()
  }
}

const readPlanId = () =>
  withBrowser(() => {
    const planIdParam = window.location.search
      .substring(1)
      .split('&')
      .find(param => param.startsWith('planId'))
    return planIdParam ? planIdParam.split('=')[1] : null
  })

const usePlan = () => {
  const [_plan, setPlan] = useState()
  const [_error, setError] = useState()

  useEffect(() => {
    const planId = readPlanId()

    if (planId) {
      fetch(
        'https://sporzz.s3-eu-west-1.amazonaws.com/runners-core/plans/' +
          planId +
          '.json'
      )
        .catch(err => setError(err))
        .then(resp => resp.json())
        .then(plan => {
          setPlan(plan)
        })
      // fetch...
    }
  }, [])

  return { plan: _plan, error: _error }
}

const App = () => {
  const [isStarted, setStarted] = useState(false)
  const { plan, error } = usePlan()

  useLayoutEffect(() => {
    if (!plan) {
      return
    }

    withBrowser(() => {
      window.document.title = plan.title + ' - Sporzz trainer'
    })
  }, [plan])

  if (error) {
    return <Error>{error}</Error>
  }

  if (!plan) {
    return <Error>Ladataan...</Error>
  }

  return isStarted ? (
    <Player plan={plan} />
  ) : (
    <Splash plan={plan} onClick={() => setStarted(true)} />
  )
}

const Error = ({ children }) => {
  return <div className='message'>{children}</div>
}

export default App
